// frontend/src/stores/authStore.js
import { defineStore } from 'pinia';
import axios from 'axios';
import { myMSALObj, state } from '../config/msalConfig';
import router from '../router';


export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem('user-token') || '',
    isAuthenticated: !!localStorage.getItem('user-token'),
    user: state.user,
    errorMessage: '',
    interactionInProgress: false,
    userDetailsCache: null,
    lastRequestTime: null,
  }),
  actions: {
    saveToken(token) {
      this.token = token;
      localStorage.setItem('user-token', token);
      this.isAuthenticated = true;
    },
    removeToken() {
      this.token = '';
      localStorage.removeItem('user-token');
      this.isAuthenticated = false;
      router.push({ name: 'ConnexionTS' }); // Redirection vers la page de connexion lors de la déconnexion
    },
    async initializeMsal() {
      try {
        await myMSALObj.initialize();
      } catch (error) {
        console.error('MSAL Initialization error:', error);
        this.errorMessage = 'Erreur d\'initialisation';
      }
    },
    async login() {
      if (this.interactionInProgress) {
        this.errorMessage = 'Interaction en cours. Veuillez patienter.';
        return;
      }

      this.interactionInProgress = true;

      try {
        await this.initializeMsal();
        console.log('MSAL Initialized. Starting loginRedirect');
        await myMSALObj.loginRedirect();
      } catch (error) {
        console.error('Erreur de connexion', error);
        this.errorMessage = error.errorMessage || 'Erreur de connexion';
      } finally {
        this.interactionInProgress = false;
      }
    },
    async fetchUserProfile() {
      try {
        const response = await axios.get('https://test.orbists.com/api/profile');
        this.user = response.data.user;
      } catch (error) {
        console.error('Fetch user profile error:', error);
      }
    },
    async logout() {
      if (this.interactionInProgress) {
        this.errorMessage = 'Interaction en cours. Veuillez patienter.';
        return;
      }

      this.interactionInProgress = true;

      try {
        await this.initializeMsal();
        await myMSALObj.logoutRedirect();
        this.removeToken();
        state.isAuthenticated = false;
        state.user = null;
        router.push({ name: 'ConnexionTS' }); // Redirection vers la page de connexion
      } catch (error) {
        this.errorMessage = 'Erreur de déconnexion';
      } finally {
        this.interactionInProgress = false;
      }
    },
    async handleRedirect() {
      try {
        await this.initializeMsal();
        const response = await myMSALObj.handleRedirectPromise();
        if (response !== null) {
          this.saveToken(response.accessToken);
          this.user = response.account;
          state.isAuthenticated = true;
          state.user = response.account;
          await this.registerUser(response.account, response.accessToken);
          console.log(`L'utilisateur ${response.account.name} est connecté`);
          console.log('Token d\'accès:', response.accessToken);
          await this.getUserDetails(response.accessToken);
          router.push({ name: 'DashboardTSa' }); // Redirection après l'authentification
        } else {
          const accounts = myMSALObj.getAllAccounts();
          if (accounts.length > 0) {
            this.user = accounts[0];
            state.isAuthenticated = true;
            state.user = accounts[0];
            console.log(`L'utilisateur ${accounts[0].name} est connecté`);
            await this.getUserDetails(this.token);
          }
        }
      } catch (error) {
        console.error('Erreur de redirection', error);
        this.errorMessage = 'Erreur de redirection';
        router.push({ name: 'ConnexionTS' }); // Redirection vers la page de connexion en cas d'erreur
      }
    },
    async getUserDetails(token) {
      if (this.userDetailsCache && this.lastRequestTime && (Date.now() - this.lastRequestTime < 60000)) {
        console.log('Informations utilisateur de Microsoft Graph (en cache):', this.userDetailsCache);
        return this.userDetailsCache;
      }

      try {
        const graphResponse = await axios.get('https://graph.microsoft.com/v1.0/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.userDetailsCache = graphResponse.data;
        this.lastRequestTime = Date.now();
        console.log('Informations utilisateur de Microsoft Graph:', graphResponse.data);
        return graphResponse.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des informations utilisateur de Microsoft Graph:', error);
        if (error.response) {
          console.error('Détails de l\'erreur:', error.response.data);
        }
      }
    },
    async registerUser(user, token) {
      try {
        const response = await axios.post('https://test.orbists.com/api/auth/register', {
          email: user.username,
          name: user.name
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log('Utilisateur enregistré avec succès', response.data);
      } catch (error) {
        console.error('Erreur d\'enregistrement de l\'utilisateur', error.response?.data || error.message);
        this.errorMessage = 'Erreur d\'enregistrement de l\'utilisateur';
      }
    },
    async createApiInstance() {
      const api = axios.create({
        baseURL: 'https://test.orbists.com/api',
      });

      api.interceptors.request.use(config => {
        const token = this.token;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
          console.log('Token ajouté aux en-têtes:', token);
        } else {
          console.log('Aucun token trouvé');
        }
        return config;
      }, error => {
        return Promise.reject(error);
      });

      console.log('API instance created:', api);
      console.log('Base URL:', api.defaults.baseURL);
      console.log('Headers:', api.defaults.headers);

      return api;
    },
  }
});
